<template>
  <div>
    <CtaHero />
    <CtaAbout />
    <CtaFeatures />
    <CtaEtalage />
    <CtaBrands />
    <CtaWorkshops />
    <!-- <CtaInstagram /> -->
    <CtaNewsletter class="sm:hidden mx-4 my-12" />
  </div>
</template>

<script setup>
const { find } = useStrapi();

const { data } = await useAsyncData('home-page', () =>
  find('home-page', {
    populate: ['seo', 'seo.metaImage'],
  })
);

usePageSeo(data.value?.data?.seo);
</script>

<style lang="scss" scoped></style>
