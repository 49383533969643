<template>
  <section>
    <div class="md:hidden w-full aspect-[3/1] max-h-[120px] -mb-16">
      <NuxtImg
        class="object-cover object-center w-full h-full shadow"
        src="/deco/stoffen.jpg"
        loading="lazy"
      />
    </div>
    <LayoutBasic>
      <div class="w-full grid grid-cols-4 grid-rows-[auto_auto] gap-x-12 pb-8">
        <div
          class="col-span-4 md:col-span-3 pt-12 md:pt-8 lg:pr-8 md:mr-8 lg:mr-16 xl:mr-24"
        >
          <h2 class="heading-basic font-platform">Breng het in de praktijk</h2>
          <p class="text-basic">
            Wil jij aan de slag? Volg een workshop bij ons en laat je
            creativiteit de vrije loop! Bij Los Naaimachines organiseren we
            regelmatig leuke workshops en trainingen voor volwassenen en
            kinderen. Of je nu een ervaren naaister bent of juist net begint,
            met ieder niveau kan je bij ons terecht.
          </p>
          <p class="text-basic mb-5 md:mb-8">
            Benieuwd wat er op de planning staat? Bekijk hieronder de
            eerstvolgende trainingen en workshops, en schrijf je direct in.
          </p>

          <div class="flex mt-8 sm:hidden">
            <NuxtLink to="/workshops" class="btn-main">
              Bekijk onze workshops
            </NuxtLink>
          </div>
        </div>
      </div>
      <CarouselContainer ariaLabel="Lijst met onze eerstvolgende workshops.">
        <NuxtLink
          v-for="workshop in workshops"
          :key="workshop.id"
          :to="{
            name: 'workshops-slug',
            params: { slug: workshop.type.slug },
            query: { id: workshop.id },
          }"
        >
          <WorkshopFrontItem
            :workshop="workshop"
            :type-name="workshop.type.name"
            :image="workshop.type.img"
          />
        </NuxtLink>
        <NuxtLink
          :to="{
            name: 'workshops',
          }"
        >
          <div
            class="h-full w-52 sm:w-60 md:w-72 rounded-lg px-16 font-bold text-lg md:text-xl flex items-center justify-center text-center bg-los-blue-lightest border-2 border-dashed border-los-blue-base text-los-blue-darkest hover:underline flex-shrink-0 shadow"
          >
            <p>
              Bekijk al onze workshops
              <FontAwesome class="text-sm md:text-xl" icon="chevron-right" />
            </p>
          </div>
        </NuxtLink>
      </CarouselContainer>
      <div class="flex justify-end mt-5">
        <NuxtLink
          to="/workshops"
          class="text-lg font-bold text-los-pink-darkest hover:underline"
          ><span aria-hidden="true">></span> Bekijk al onze workshops</NuxtLink
        >
      </div>
    </LayoutBasic>
  </section>
</template>

<script setup>
import dayjs from 'dayjs';

const { find } = useStrapi();

const { data: workshopData } = await useAsyncData('workshops-cta', () =>
  find('workshops', {
    populate: ['extra_dates', 'type', 'type.img', 'participants'],
    filters: {
      start_date_time: {
        $gte: dayjs().format(),
      },
      type: { hidden: { $ne: true } },
    },
    sort: ['start_date_time'],
    pagination: {
      pageSize: 5,
    },
  })
);

const workshops = computed(() => {
  return workshopData.value?.data;
});
</script>

<style lang="scss" scoped></style>
