<template>
  <section class="w-full relative md:pb-8 lg:pb-20">
    <div class="max-w-screen-xl mx-auto">
      <VerticalBorderDivider />
      <div class="flex flex-col items-center gap-y-8 pt-16 lg:pt-20">
        <div class="w-full px-basic lg:w-auto lg:text-center">
          <h2
            class="text-2xl sm:text-4xl font-normal mb-2 lg:mb-6 !leading-relaxed font-platform"
          >
            In de etalage<span class="block text-xl sm:text-4xl font-light"
              >Onze eigen favorieten</span
            >
          </h2>
        </div>
      </div>
    </div>

    <LayoutBasic class="md:mt-10">
      <CarouselContainer
        contentCentered
        ariaLabel="Onze machines in de etalage."
      >
        <MachineItem
          v-for="machine in machinesData?.data"
          :key="machine.id"
          :machine="machine"
          is-etalage-item
          class="shrink-0"
        />
      </CarouselContainer>
    </LayoutBasic>

    <div class="hidden lg:flex justify-center mt-8">
      <NuxtLink to="/machines">
        <div
          class="hover:underline font-bold text-lg text-center text-los-pink-darkest"
        >
          > Meer zien? Bekijk dan hier al onze machines.
        </div>
      </NuxtLink>
    </div>
  </section>
</template>

<script setup>
const { find } = useStrapi();

const { data: machinesData } = await useAsyncData('etalage', () =>
  find('machines', {
    filters: {
      featured: { $eq: true },
    },
    populate: ['type', 'brand', 'list_image'],
    pagination: {
      pageSize: 4,
    },
  })
);
</script>

<style lang="scss" scoped></style>
