<template>
  <section class="pl-4 sm:pl-8 md:px-12 lg:px-16 my-0 md:my-16 lg:my-20 w-full">
    <div class="max-w-screen-xl mx-auto">
      <div
        class="grid grid-cols-[1fr_80px] sm:grid-cols-[1fr_120px] md:grid-cols-5 items-center gap-x-6 sm:gap-x-8"
      >
        <div class="col-span-2 relative hidden md:block">
          <div class="aspect-[3/4] max-w-80 shadow-sm relative">
            <NuxtImg
              class="object-cover object-center w-full h-full shadow"
              src="/deco/garen_zijkant.webp"
              alt="Garen in de winkel."
              preload
            />
            <CircleCraft
              class="absolute fill-los-purple-base w-32 h-32 -top-10 -right-8 lg:top-16 lg:-right-16 xl:-right-24 lg:w-36 lg:h-36 xl:w-44 xl:h-44 z-10 animate-spin-slow"
            />
          </div>
        </div>
        <div
          class="md:col-span-3 relative max-w-3xl pt-20 py-12 md:pt-0 sm:py-16 md:py-0 md:pl-10 lg:px-10"
        >
          <div
            class="absolute md:hidden w-24 h-24 -top-10 -left-12 sm:-left-16 z-10 animate-spin-slow fill-los-purple-base"
          >
            <CircleCraft />
          </div>
          <h2 class="heading-basic font-platform">Let’s get creative</h2>
          <p class="text-basic max-md:mb-10 mb-8">
            Bij Los Naaimachines is creativiteit onze kracht en onze passie. We
            hebben alles in huis om jouw ideeën werkelijkheid te laten worden,
            of je nu kleding, accessoires of quilts wil maken. Kom langs voor
            een mooie nieuwe stof, kom snuffelen bij onze fournituren, bekijk
            onze collectie van machines of laat je creativiteit de vrije loop
            bij een workshop. Let’s get creative!
          </p>
          <NuxtLink href="/over-ons" class="btn-blue">
            Lees meer over ons verhaal
          </NuxtLink>
        </div>
        <div class="md:hidden h-full">
          <NuxtImg
            class="object-cover object-left-bottom w-full h-full"
            src="/deco/verticaal_klosjes.webp"
            alt="Verticaal paarse klosjes."
            preload
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>

<style lang="scss" scoped></style>
