<template>
  <section
    class="py-16 sm:py-24 px-4 sm:px-8 md:px-24 lg:px-16 bg-los-purple-lightest"
  >
    <h2
      aria-hidden="true"
      class="heading-basic mb-10 sm:mb-12 ml-10 sm:ml-20 lg:ml-0 lg:text-center font-platform"
    >
      Waar wij om bekendstaan
    </h2>

    <ul
      aria-label="Waar wij om bekend staan:"
      class="max-w-screen-xl mx-auto flex flex-col lg:flex-row gap-y-8 gap-x-12 lg:gap-x-16 justify-between"
    >
      <li
        class="lg:w-1/3 lg:max-w-[20rem] flex lg:flex-col lg:items-center lg:text-center"
      >
        <div aria-hidden="true" class="h-12 lg:h-20">
          <div class="w-12 h-12 lg:w-16 lg:h-16 shrink-0">
            <IconExpert />
          </div>
        </div>
        <div class="pl-8 lg:pl-0">
          <h3 class="heading-basic lg:text-2xl font-platform mb-2">
            Expertise
          </h3>
          <p class="text-basic">
            Veel kennis, snelle service en praktische instructies: dat is Los
            Naaimachines.
          </p>
        </div>
      </li>
      <li
        class="lg:w-1/3 lg:max-w-[20rem] flex lg:flex-col lg:items-center lg:text-center"
      >
        <div aria-hidden="true" class="h-12 lg:h-20">
          <div class="w-12 h-12 lg:w-16 lg:h-16 shrink-0">
            <IconRepair />
          </div>
        </div>
        <div class="pl-8 lg:pl-0">
          <h3 class="heading-basic lg:text-2xl font-platform mb-2">
            Reparaties
          </h3>
          <p class="text-basic">
            Onze monteurs hebben jarenlange ervaring. Reparaties van alle merken
            naai-, borduur-, lock- en covermachines.
          </p>
        </div>
      </li>
      <li
        class="lg:w-1/3 lg:max-w-[20rem] flex lg:flex-col lg:items-center lg:text-center"
      >
        <div aria-hidden="true" class="h-12 lg:h-20">
          <div class="w-12 h-12 lg:w-[4.5rem] lg:h-[4.5rem] shrink-0">
            <IconService />
          </div>
        </div>
        <div class="pl-8 lg:pl-0">
          <h3 class="heading-basic lg:text-2xl font-platform mb-2">Service</h3>
          <p class="text-basic">
            Bij ons koop je zonder zorgen. Wij bieden uitstekende klantenservice
            en garantie op al onze producten.
          </p>
        </div>
      </li>
    </ul>
  </section>
</template>

<script setup></script>

<style lang="scss" scoped></style>
