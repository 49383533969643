<template>
  <section class="w-full relative bg-los-blue-lightest">
    <div class="px-8 z-10">
      <div class="max-w-screen-2xl mx-auto">
        <HorizontalSlashDivider />
      </div>
    </div>

    <div class="px-basic py-basic">
      <div class="max-w-screen-xl mx-auto">
        <div class="flex flex-col lg:flex-row">
          <div
            class="md:max-w-screen-sm lg:max-w-none lg:px-0 lg:basis-2/5 lg:pr-10 mb-10 lg:mb-0"
          >
            <h2 class="heading-basic font-platform">Machinemerken</h2>

            <p class="justify-stretch text-basic">
              Wij werken alleen samen met de allerbeste merken in de naaiwereld.
              Dat is een belofte. En weet je wat daar fijn aan is? Doordat we zo
              nauw samenwerken met al deze topmerken, kunnen we jou altijd de
              beste kwaliteit leveren!
            </p>

            <NuxtLink to="/machines" class="hidden lg:btn-blue mt-4">
              Bekijk al onze machines
            </NuxtLink>
          </div>
          <ul
            aria-label="Al onze merken"
            class="lg:basis-3/5 grid grid-cols-2 min-[380px]:grid-cols-3 gap-10 md:gap-10 items-center lg:pr-8"
          >
            <li class="flex justify-center">
              <NuxtLink
                to="/machines/alle-machines/baby-lock"
                title="Bekijk al onze machines van Baby Lock."
              >
                <NuxtImg
                  class="max-h-10 w-full max-auto max-w-[80px] sm:max-w-[140px] object-contain object-center col-span-1"
                  title="baby-lock"
                  alt="Logo van baby-lock."
                  src="/logos/baby-lock.png"
                  loading="lazy"
                />
              </NuxtLink>
            </li>
            <li class="flex justify-center">
              <NuxtLink
                to="/machines/alle-machines/bernette"
                title="Bekijk al onze machines van Bernette."
              >
                <NuxtImg
                  class="max-h-10 w-full max-auto max-w-[80px] sm:max-w-[140px] object-contain object-center col-span-1"
                  title="bernette"
                  alt="Logo van bernette."
                  src="/logos/bernette.png"
                  loading="lazy"
                />
              </NuxtLink>
            </li>
            <li class="flex justify-center">
              <NuxtLink
                to="/machines/alle-machines/bernina"
                title="Bekijk al onze machines van Bernina."
              >
                <NuxtImg
                  class="max-h-10 w-full max-auto max-w-[80px] sm:max-w-[140px] object-contain object-center col-span-1"
                  title="bernina"
                  alt="Logo van bernina."
                  src="/logos/bernina.png"
                  loading="lazy"
                />
              </NuxtLink>
            </li>
            <li class="flex justify-center">
              <NuxtLink
                to="/machines/alle-machines/brother"
                title="Bekijk al onze machines van Brother."
              >
                <NuxtImg
                  class="max-h-10 w-full max-auto max-w-[80px] sm:max-w-[140px] object-contain object-center col-span-1"
                  title="brother"
                  alt="Logo van brother."
                  src="/logos/brother.png"
                  loading="lazy"
                />
              </NuxtLink>
            </li>
            <li class="flex justify-center">
              <NuxtLink
                to="/machines/alle-machines/husqvarna"
                title="Bekijk al onze machines van Husqvarna."
              >
                <NuxtImg
                  class="max-h-10 w-full max-auto max-w-[80px] sm:max-w-[140px] object-contain object-center col-span-1"
                  title="husqvarna-viking"
                  alt="Logo van husqvarna-viking."
                  src="/logos/husqvarna-viking.png"
                  loading="lazy"
                />
              </NuxtLink>
            </li>
            <li class="flex justify-center">
              <NuxtLink
                to="/machines/alle-machines/janome"
                title="Bekijk al onze machines van Janome."
              >
                <NuxtImg
                  class="max-h-10 w-full max-auto max-w-[80px] sm:max-w-[140px] object-contain object-center col-span-1"
                  title="janome"
                  alt="Logo van janome."
                  src="/logos/janome.png"
                  loading="lazy"
                />
              </NuxtLink>
            </li>
            <li class="flex justify-center">
              <NuxtLink
                to="/machines/alle-machines/juki"
                title="Bekijk al onze machines van Juki."
              >
                <NuxtImg
                  class="max-h-10 w-full max-auto max-w-[80px] sm:max-w-[140px] object-contain object-center col-span-1"
                  title="juki"
                  alt="Logo van juki."
                  src="/logos/juki.png"
                  loading="lazy"
                />
              </NuxtLink>
            </li>
            <li class="flex justify-center">
              <NuxtLink
                to="/machines/alle-machines/lewenstein"
                title="Bekijk al onze machines van Lewenstein."
              >
                <NuxtImg
                  class="max-h-10 w-full max-auto max-w-[80px] sm:max-w-[140px] object-contain object-center col-span-1"
                  title="lewenstein"
                  alt="Logo van lewenstein."
                  src="/logos/lewenstein.png"
                  loading="lazy"
                />
              </NuxtLink>
            </li>
            <li class="flex justify-center">
              <NuxtLink
                to="/machines/alle-machines/pfaff"
                title="Bekijk al onze machines van Pfaff."
              >
                <NuxtImg
                  class="max-h-10 w-full max-auto max-w-[80px] sm:max-w-[110px] object-contain object-center col-span-1"
                  title="pfaff"
                  alt="Logo van pfaff."
                  src="/logos/pfaff.png"
                  loading="lazy"
                />
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>

<style lang="scss" scoped></style>
