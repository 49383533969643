<template>
  <div
    class="w-full relative bg-los-blue-lightest border-b-[2px] border-dashed"
    :class="{
      'border-los-blue-base': animationActive,
      'border-transparent': !animationActive,
    }"
  >
    <div class="w-full">
      <Transition
        mode="out-in"
        enter-active-class="transition ease-out duration-200 opacity-0 "
        enter-from-class="opacity-0 translate-x-1"
        enter-to-class="opacity-100 translate-x-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-x-0"
        leave-to-class="opacity-0 -translate-x-1"
      >
        <div :key="curBlockIndex" class="max-w-screen-xl mx-auto">
          <div class="grid md:grid-cols-2 md:h-[500px]">
            <div
              class="w-full flex flex-col items-center md:items-start justify-center md:max-w-[36rem] px-8 py-16 lg:py-0 lg:px-16 max-md:col-span-full"
            >
              <div class="mb-10 text-center md:text-start sm:px-10 md:px-0">
                <h1
                  class="text-3xl mb-6 lg:text-4xl lg:leading-normal font-platform"
                  v-html="block.title"
                ></h1>
                <p class="text-lg lg:text-xl lg:leading-normal">
                  {{ block.subtitle }}
                </p>
              </div>

              <div
                class="flex flex-row justify-center gap-x-8 flex-wrap gap-y-4"
              >
                <NuxtLink
                  v-for="link in block.links"
                  :to="link.url"
                  :external="link.external"
                  :target="link.external ? '_blank' : '_self'"
                >
                  <div class="btn-main">{{ link.title }}</div>
                </NuxtLink>
              </div>
            </div>

            <div class="relative hidden md:block col-span-1 h-full">
              <div
                class="absolute left-0 top-0 h-full w-[50vw] flexitems-center"
              >
                <NuxtImg
                  :src="block.image.url"
                  class="h-full w-full object-cover object-center"
                  :alt="block.image.alt"
                  preload
                />
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <div
      v-if="animationActive && totalLengthBlocks > 1"
      :key="curBlockIndex + '-progress'"
      class="absolute top-full h-[2px] w-full z-10 flex justify-end"
    >
      <div
        class="h-full bg-white progress-bar"
        :style="{
          animationDuration: `${animationTime - 400}ms`,
        }"
      ></div>
    </div>
  </div>
</template>

<script setup>
const { find } = useStrapi();

const animationTime = 7500; // 7.5 sec
const minDesktopSize = 768; // md:

const defaultBlock = {
  title: 'Dé winkel voor<br>naailiefhebbers',
  subtitle:
    'Bij Los Naaimachines ben je aan het juiste adres, wat je naainiveau ook is.',
  links: [
    { title: 'Onze machines', url: '/machines', external: false },
    { title: 'Workshops', url: '/workshops', external: false },
  ],
  image: {
    url: '/deco/los_voorgevel_md.webp',
    alt: 'Naaimachine klosjes',
  },
};

const curBlockIndex = ref(0);
const animationActive = ref(false);
let heroInterval;

const response = await useAsyncData(
  'blocks',
  () =>
    find('home-blocks', {
      populate: ['image'],
    }),
  {
    server: false,
  }
);

const blocks = computed(() => {
  return [
    defaultBlock,
    ...(response?.data?.value?.data?.map(
      ({ title, subtitle, url, image: img }) => {
        let image = {
          url: useStrapiImage(img, 'medium'),
          alt: title,
        };
        return {
          title,
          subtitle,
          links: [{ title: 'Lees meer', url, external: false }],
          image,
        };
      }
    ) ?? []),
  ];
});

if (import.meta.client) {
  watch(blocks, setBlocksAnimation);
}

onMounted(() => {
  if (blocks.value?.length) {
    setBlocksAnimation();
  }
});

const block = computed(() => {
  return blocks.value[curBlockIndex.value];
});

const totalLengthBlocks = computed(() => {
  return blocks.value.length;
});

function setBlocksAnimation() {
  const { innerWidth } = window;
  if (totalLengthBlocks.value < 2 || innerWidth < minDesktopSize) {
    return;
  }

  animationActive.value = true;
  heroInterval = setInterval(() => {
    if (curBlockIndex.value === totalLengthBlocks.value - 1) {
      curBlockIndex.value = 0;
    } else {
      curBlockIndex.value++;
    }
  }, animationTime);

  window.addEventListener('resize', checkForSmallScreenSize);
  window.dispatchEvent(new Event('resize'));
}

function checkForSmallScreenSize(event) {
  if (window && event.srcElement.innerWidth < minDesktopSize) {
    curBlockIndex.value = 0;
    animationActive.value = false;
    clearInterval(heroInterval);
    window.removeEventListener('resize', checkForSmallScreenSize);
  }
}

useHead({
  link: blocks.value.map(({ image }) => {
    if (!image) {
      return false;
    }
    return {
      href: image?.url,
      rel: 'preload',
      as: 'image',
    };
  }),
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.progress-bar {
  animation: progress linear;
}

@keyframes progress {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
</style>
