<template>
  <div class="bg-los-pink-lightest rounded-lg overflow-hidden">
    <div class="aspect-[2/1] max-h-44 w-full">
      <NuxtImg
        class="object-cover object-center w-full h-full"
        src="/deco/los_voorgevel_sm.jpg"
        loading="lazy"
      />
    </div>
    <div class="py-8 px-6">
      <h3 class="heading-basic text-los-pink-darkest font-platform">
        Ontvang de LosBrief
      </h3>
      <p class="text-basic mb-4">
        Met de Losbrief ben je altijd als eerste op de hoogte van de leukste
        aanbiedingen en de laatste nieuwtjes. Schrijf je direct in!
      </p>
      <NewsletterForm color="pink-darkest" />
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
